// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../dash-autocomplete-input/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../dash-autocomplete-input/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-autocomplete-input {
   background-clip: padding-box;
   background-color: #fff;
   border: 1px solid rgba(0,0,0,0.15);
   bottom: auto;
   box-shadow: 0 6px 12px rgba(0,0,0,0.175);
   display: block;
   font-size: 14px;
   list-style: none;
   padding: 1px;
   position: fixed;
   text-align: left;
   z-index: 20000;
   overflow-y: scroll;
   margin-top: 1em;
}

.react-autocomplete-input > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
}

.react-autocomplete-input > li.active {
  background-color: #337ab7;
  color: #fff;
}

`, "",{"version":3,"sources":["webpack://./../react-autocomplete-input/dist/bundle.css"],"names":[],"mappings":"AAAA;GACG,4BAA4B;GAC5B,sBAAsB;GACtB,kCAAkC;GAClC,YAAY;GACZ,wCAAwC;GACxC,cAAc;GACd,eAAe;GACf,gBAAgB;GAChB,YAAY;GACZ,eAAe;GACf,gBAAgB;GAChB,cAAc;GACd,kBAAkB;GAClB,eAAe;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".react-autocomplete-input {\n   background-clip: padding-box;\n   background-color: #fff;\n   border: 1px solid rgba(0,0,0,0.15);\n   bottom: auto;\n   box-shadow: 0 6px 12px rgba(0,0,0,0.175);\n   display: block;\n   font-size: 14px;\n   list-style: none;\n   padding: 1px;\n   position: fixed;\n   text-align: left;\n   z-index: 20000;\n   overflow-y: scroll;\n   margin-top: 1em;\n}\n\n.react-autocomplete-input > li {\n  cursor: pointer;\n  padding: 10px;\n  min-width: 100px;\n}\n\n.react-autocomplete-input > li.active {\n  background-color: #337ab7;\n  color: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
